const outOption = {
  title: "告警记录",
  column: [
    {
      label: '姓名',
      prop: 'personName',
    },
    {
      label: "告警类型",
      prop: 'type',
    },
    {
      label: "部门",
      prop: "deptName",
    },
    {
      label: "告警时间",
      prop: "reportTime",
    },
    {
      label: "地点",
      prop: "address",
    },
  ],
};
export default outOption
