import request from "@/utils/request";

// 分页查询打卡记录
export const electronAlertRecordPage = (params)=>{
  return request({
    url: "/electron/alertRecord/page",
    method: "get",
    params,
  });
}
