export const tableOption = {
  border: true,
  stripe: true,
  menuAlign: 'center',
  selection: true,
  tip: false,
  searchMenuSpan: 6,
  align: 'center',
  editBtn: false,
  delBtn: false,
  addBtn: false,
  column : [
    {
      label: "姓名",
      prop: "personName",
    },
    {
      label: "告警类型",
      type:'select',
      dicData:[
        {
          label:'全部告警',
          value:''
        },
        {
          label:'SOS告警',
          value:1
        },
        {
          label:'围栏告警',
          value:2
        },
        {
          label:'电量告警',
          value:3
        },
      ],
      prop: "type",
    },
    {
      label: "部门",
      prop: "deptName",
    },
    {
      label: "告警时间",
      prop: "reportTime",
    },
    {
      label: "地点",
      prop: "address",
    },
  ]
}
