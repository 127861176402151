<template>
  <el-dialog title="位置" :append-to-body="true"
  :visible.sync="visible" top="10vh" width="50%">
    <div id="mapLocation"></div>
  </el-dialog>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      visible: false,
      map: null,
      AMap: null
    }
  },
  methods: {
    show(position) {
      this.visible = true
      this.initMap(position)
      // this.map.setCenter(JSON.parse(position))
    },
    hide() {
      this.visible = false
    },
    // 初始化地图
    async initMap(position) {
      this.AMap = await AMapLoader.load({
        key: "a0431274a06861ab57ac681244e7d20f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      });
      this.map = new this.AMap.Map("mapLocation", {
        //设置地图容器id
        // viewMode:"3D",    //是否为3D地图模式
        zoom: 16, //初始化地图级别
        center: position, //初始化地图中心点位置
        // mapStyle: "amap://styles/darkblue",
      });
      this.map.on('complete', () => {
        console.log('地图加载成功')
      })
      const marker = new AMap.Marker({
        position: new AMap.LngLat(position[0], position[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      this.map.add(marker);
    },
  }
}
</script>


<style lang="scss">
#mapLocation {
  width: 100%;
  height: 400px;
}
.el-form-item__label{
  color: #a3d6ff
}
.el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.el-dialog__header {
  background: url("../../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.el-dialog__body {
  background: #0c265a;
}
.el-dialog__footer {
  background: #0c265a;
  border: none !important;
}
</style>
